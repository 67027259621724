import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { WorkArea, IBackendWorkArea } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class WorkAreaService {

  constructor(
    private _http: HttpClient,
  ) { }

  getWorkareas() {
    return this._http.get(environment.API_URL + 'work_area/all')
    .pipe(map((data: any) => ({
      workareas: (data.work_areas || []).map(workarea => new WorkArea().parse(workarea))
    })));
  }

  getWorkarea(workareaId: number) {
    const queryParams = [
      `load_country_jobs=` + true,
    ];

    return this._http.get(environment.API_URL + `work_area/` + workareaId + `?${ queryParams }`)
      .pipe(map((data: any) => ({
        workarea: new WorkArea().parse(data['work_area']),
        countryWorkAreas: (data['more_workareas'] || []).map((workarea) => new WorkArea().parse(workarea)),
      })));
  }

  saveWorkarea(workarea: WorkArea) {
    let url = 'work_area/save';

    if (workarea.id !== 0) {
      url = url + '/' + workarea.id;
    }
    const params = {
      title: workarea.title,
      body: workarea.body,
      location_id: workarea.locationId,
      cover_path: workarea.coverPath,
      languages: workarea.languages.map(language => {
        return {
          id: language.id,
          body: language.body,
          title: language.title,
          language: language.language,
       }; })
    };

    return this._http.post(environment.API_URL + url, params)
    .pipe(map((data) => ({
      workareas: data['work_area'].map((workarea: IBackendWorkArea) => new WorkArea().parse(workarea))
    })));
  }

  deleteWorkArea(WorkAreaId: number) {
    return this._http.delete(environment.API_URL + 'work_area/' + WorkAreaId)
    .pipe(map((data) => ({
      workarea: true
    })));
  }
}
