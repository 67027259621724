import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Initiative, IBackendInitiative } from '../../models';

@Injectable({
  providedIn: 'root'
})
export class InitiativeService {

  constructor(
    private _http: HttpClient,
  ) { }

  getInitiatives() {
    return this._http.get(environment.API_URL + 'initiative/all')
    .pipe(map((data: any) => ({
      initiatives: (data.initiatives || []).map(initiative => new Initiative().parse(initiative))
    })));
  }

  getInitiative(initiativeId: number) {
    const queryParams = [
      `load_country_initiatives=` + true,
    ];

    return this._http.get(environment.API_URL + `initiative/` + initiativeId + `?${ queryParams }`)
      .pipe(map((data: any) => ({
        initiative: new Initiative().parse(data['initiative']),
        countryInitiatives: (data['more_initiatives'] || []).map((initiative) => new Initiative().parse(initiative)),
      })));
  }

  saveInitiative(initiative: Initiative) {
    let url = 'initiative/save';

    if (initiative.id !== 0) {
      url = url + '/' + initiative.id;
    }
    const params = {
      title: initiative.title,
      body: initiative.body,
      location_id: initiative.locationId,
      cover_path: initiative.coverPath,
      slider: initiative.slider,
      languages: initiative.languages.map(language => {
        return {
          id: language.id,
          body: language.body,
          title: language.title,
          language: language.language,
       }; })
    };

    return this._http.post(environment.API_URL + url, params)
    .pipe(map((data) => ({
      initiatives: data['initiative'].map((initiative: IBackendInitiative) => new Initiative().parse(initiative))
    })));
  }

  deleteInitiative(initiativeId: number) {
    return this._http.delete(environment.API_URL + 'initiative/' + initiativeId)
    .pipe(map((data) => ({
      initiative: true
    })));
  }
}
